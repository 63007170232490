import axios from 'axios';
import './api.css';

const baseURL = process.env.REACT_APP_API_URL || "http://localhost:3000/api";
const TOKEN_REFRESH_INTERVAL = 60 * 1000;

class Api {
    constructor() {
        this.client = axios.create({baseURL});
        this.client.interceptors.request.use(request => {
            request.headers = {...request.headers, Authorization: `Bearer ${this.accessToken}`};
            return request;
        });

        this.client.interceptors.response.use(response => response, (e) => {
            // if (e.config.url.includes("auth/user")) {
            //     return e.response;
            // }
            if ((!e.config.url.includes('auth/token_refresh') && !e.config.url.includes('login')) && (e.response.status === 401 || e.response.status === 403)) {
                const originalRequest = e.config;
                return this.client.get('/auth/token_refresh').then(response => {
                    this.accessToken = response.data.token;
                    originalRequest.headers.Authorization = `Bearer ${this.accessToken}`;
                    return this.client.request(originalRequest);
                }).catch(() => Promise.reject(e)); // returning original error if token refresh fails
            }
            return Promise.reject(e);
        });

        this.accessToken = null;
        window.setInterval(() => this._refreshToken(), TOKEN_REFRESH_INTERVAL);
    }

    _refreshToken() {
        if (this.accessToken) {
            this.client.get('/auth/token_refresh').then(response => {
                this.accessToken = response.data.token;
            });
        }
    }

    async checkSession() {
        return await this.client.get('session_check');
    }

    async doLogin(username, password) {
        try {
            const response = await this.client.post('/auth/login', {username, password});
            this.accessToken = response.data.token;
            return response;
        } catch (e) {
            throw new Error("Authentication failed");
        }
    }

    async doLogout() {
        try {
            await this.client.get('/token_invalidate');
            this.accessToken = null;
        } catch (e) {
            throw new Error("Logout failed");
        }
    }

    sortToString(sort) {
        if (sort.sortBy !== null) {
            return sort.sortBy + '.' + sort.sortDirection;
        }
        return null;
    }

    buildQuery(params) {
        const query = {page: params.page};
        const sortString = this.sortToString(params.sorting);
        if (sortString!==null) {
            query.sort = sortString;
        }
        return query;
    }


    async getOpenTickets({page, sorting}) {
        const query = this.buildQuery({page, sorting});
        query.status = 'open';
        const qs = (new URLSearchParams(query)).toString();
        return await this.client.get('/ticket?'+qs);
    }

    async getTickets({page, sorting, terminal, filter}) {
        if (filter!==undefined) {
            return await this.client.get('/ticket?q='+encodeURI(filter));
        }
        const query = this.buildQuery({page, sorting});
        if (terminal!==undefined) {
            query.terminal = terminal;
        }
        const qs = (new URLSearchParams(query)).toString();

        return await this.client.get('/ticket?'+qs);
    }

    async changeTicketStatus(id, status) {
        const response = await this.client.patch(`/ticket/${id}`, {status});
        return response.data;
    }

    async getTicketTypes() {
        return await this.client.get('/ticket_types');
    }

    async getTerminals({page, sorting, query, terminalStatus="all"}) {
        if (query!==undefined) {
            if (terminalStatus==="all") {
                return await this.client.get('/terminal?q=' + encodeURI(query));
            }
            return await this.client.get(`/terminal?active=${terminalStatus}&q=` + encodeURI(query));

        }
        const params = this.buildQuery({page, sorting});
        const qs = (new URLSearchParams(params)).toString();
        return await this.client.get('/terminal?'+qs);
    }

    async getTerminalsForAutocomplete() {
        const response=await this.client.get('/terminal?view=compact');
        return response.data;
    }

    async getAvailableModels() {
        const response=await this.client.get('/model');
        return response.data;
    }

    fixTerminalDates(data) {
        if (data.machine && data.machine.is3DESCompliantSince!==null) {
            data.machine.is3DESCompliantSince = new Date(data.machine.is3DESCompliantSince);
        }
        ["contractExpires", "activatedDate", "deactivatedDate", "installDate"].forEach(dateKey => {
            if (data[dateKey]!==null) {
                data[dateKey] = new Date(data[dateKey]);
            }
        });
        return data;
    }

    async updateTerminal(id, updateData) {
        const { data } = await this.client.patch(`/terminal/${id}`, updateData);
        return this.fixTerminalDates(data);
    }

    async getPeople({page, sorting}) {
        const params = this.buildQuery({page, sorting});
        const qs = (new URLSearchParams(params)).toString();
        return await this.client.get('/person?'+qs);
    }

    async getUsers({page, sorting}) {
        const params = this.buildQuery({page, sorting});
        const qs = (new URLSearchParams(params)).toString();
        return await this.client.get('/user?'+qs);
    }

    async getTicketDetails(id) {
        const response = await this.client.get(`/ticket/${id}`);
        return response.data;
    }

    async getTerminalDetails(id) {
        const {data} = await this.client.get(`/terminal/${id}`);
        return this.fixTerminalDates(data);
    }

    async getStats() {
        const response = await this.client.get('/stats');
        return response.data;
    }

    async getMaintenanceRegions() {
        const response = await this.client.get('/maintenance_region');
        return response.data;
    }

    async createTicket(ticket) {
        const response = await this.client.post('/ticket', ticket, {headers: {'Content-Type': 'application/json'}});
        return response.data;
    }

    async createTerminal(terminal) {
        const response = await this.client.post('/terminal', terminal, {headers: {'Content-Type': 'application/json'}});
        return response.data;
    }

    async postNote(ticketId, note) {
        const response = await this.client.post(`/ticket/${ticketId}/note`, {note}, {headers: {'Content-Type': 'application/json'}});
        return response.data;
    }

    async updatePassword(password) {
        return await this.client.post('/user/change_password', {password}, {headers: {'Content-Type': 'application/json'}});
    }
}

export default new Api();
