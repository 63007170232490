import React, {lazy, useEffect, useState} from 'react'
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom'
import AccessibleNavigationAnnouncer from './components/AccessibleNavigationAnnouncer'
import Api from './utils/api';

const Layout = lazy(() => import('./containers/Layout'))
const Login = lazy(() => import('./pages/Login'))
const CreateAccount = lazy(() => import('./pages/CreateAccount'))
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'))

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(null);

  useEffect(() => {
    (async function check() {
      try {
        const result = await Api.checkSession();
        setIsLoggedIn(true);
      } catch (e) {
        setIsLoggedIn(false);
      }
    })();
  }, [])

  if (isLoggedIn===null) return <p>Loading...</p>;

  return (
    <>
      <Router>
        <AccessibleNavigationAnnouncer />
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/create-account" component={CreateAccount} />
          <Route path="/forgot-password" component={ForgotPassword} />

          {/* Place new routes over this */}

          <Route path="/app" component={Layout} />
          {/* If you have an index page, you can remove this Redirect */}
          <Redirect exact from="/" to={isLoggedIn===true?'/app':'/login'} />
        </Switch>
      </Router>
    </>
  )
}

export default App
